import React, {useRef,useState} from "react";
// POCBresil - BEGIN
import QRCode from "react-qr-code";
import _isEmpty from "lodash/isEmpty";

import DoneIcon from "@mui/icons-material/Check";
import DateIcon from "@mui/icons-material/DateRange";
import AssigneeIcon from "@mui/icons-material/Group";
import PersonIcon from "@mui/icons-material/Person";
import QrCodeIcon from '@mui/icons-material/QrCode';
import SettingsIcon from "@mui/icons-material/Settings";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

// POCBresil - END
import { statusIconMapping, statusMapping, unixTsToString } from "../../helper.js";
import strings from "../../localizeStrings";
import SubProjectAnalyticsDialog from "../Analytics/SubProjectAnalyticsDialog";

import SubProjectAssigneeContainer from "./SubProjectAssigneeContainer";

const styles = {
  container: {
    display: "flex",
    height: "30%",
    flex: 1,
    flexDirection: "row",
    width: "100%",
    marginBottom: "24px",
    justifyContent: "space-between",
    alignItems: "center"
  },
  card: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  projectedBudget: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "18px",
    width: "32%"
  },
  subprojectDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "18px",
    width: "31%",
    overflowWrap: "break-word"
  },
  subprojectAssignee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "18px",
    width: "31%"
  },
  analytics: {
    padding: "12px 0 ",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableCell: {
    padding: "5px"
  }
};

const subProjectCanBeClosed = (subProjectIsClosed, userIsAllowedToClose, workflowItems) => {
  const hasOpenWorkflowitems = !_isEmpty(workflowItems.find((workflowItem) => workflowItem.data.status === "open"));
  return !subProjectIsClosed && userIsAllowedToClose && !hasOpenWorkflowitems;
};

const subProjectCloseButtonTooltip = (userIsAllowedToClose, subProjectCanBeClosed) => {
  if (subProjectCanBeClosed) {
    return strings.common.close;
  } else if (!userIsAllowedToClose) {
    return strings.subproject.subproject_close_not_allowed;
  } else {
    return strings.subproject.subproject_close_info;
  }
};

const SubProjectDetails = ({
  displayName,
  description,
  currency,
  subprojectId,
  status,
  assignee,
  workflowItems,
  created,
  canAssignSubproject,
  projectId,
  users,
  closeSubproject,
  canCloseSubproject,
  isDataLoading,
  openAnalyticsDialog,
  projectedBudgets,
  subprojectValidator,
  fixedWorkflowitemType
}) => {
  const mappedStatus = statusMapping(status);
  const statusIcon = statusIconMapping[status];
  const date = unixTsToString(created);
  const validator = users.find((user) => user.id === subprojectValidator);

  const closingOfSubProjectAllowed = subProjectCanBeClosed(status === "closed", canCloseSubproject, workflowItems);

  // POCBresil - BEGIN
  const [isQrVisible, setQrVisible] = useState(false);
  const qrRef = useRef(null);
  // POCBresil - END


  const downloadQR = () => {
    console.log("Attempting to download QR Code");
  
    if (!qrRef.current) {
      console.log("QR Code reference is null");
      return;
    }
  
    const svgElement = qrRef.current.querySelector("svg");
    if (!svgElement) {
      console.log("SVG element not found");
      return;
    }
  
    const svgData = new XMLSerializer().serializeToString(svgElement);
  
    // Création d'une image à partir des données SVG
    const img = new Image();
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
  
    img.onload = () => {
      // Création d'un canvas et dessin de l'image dessus
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
  
      // Conversion du canvas en PNG et téléchargement
      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qr-code.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  };
  
  return (
    <div style={styles.container}>
      {/* // POCBresil - BEGIN */}
      {isQrVisible ? (
        <div>
          <Dialog open={isQrVisible} onClose={() => { setQrVisible(false); }}>
            <DialogContent>
              <div ref={qrRef}>
                <QRCode
                  color="primary"
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`projectId=${projectId}&subprojectId=${subprojectId}&SubProjectName=${displayName}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={downloadQR}>
                Download
              </Button>
              <Button onClick={() => { setQrVisible(false); }} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    {/* // POCBresil - END */}
      <Card style={styles.card}>
        <List style={styles.subprojectDetails}>
          <ListItem data-test="subproject-details-displayname">
            {displayName ? (
              <ListItemAvatar>
                <Avatar>{displayName[0]}</Avatar>
              </ListItemAvatar>
            ) : null}
            <ListItemText primary={displayName} secondary={description} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DateIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={date} secondary={strings.common.created} />
          </ListItem>
          {/* <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AmountIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={currency} secondary={strings.subproject.subproject_currency} />
          </ListItem> */}
          {/* FAS !_isEmpty(fixedWorkflowitemType) ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <SettingsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={fixedWorkflowitemType} secondary={strings.workflow.workflowitem_type} />
            </ListItem>
          ) : null FAS */}
        </List>

        {/*<div style={styles.projectedBudget} data-test="subproject-projected-budget">
          <Typography variant="body1">{strings.common.projected_budget}</Typography>
          {isDataLoading ? (
            <div />
          ) : projectedBudgets.length > 0 ? (
            <div>
              <Table padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.tableCell}>{strings.common.organization}</TableCell>
                    <TableCell style={styles.tableCell} align="right">
                      {strings.common.amount}
                    </TableCell>
                    <TableCell style={styles.tableCell} align="right">
                      {strings.common.currency}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectedBudgets.map(budget => (
                    <TableRow key={budget.organization + budget.currencyCode}>
                      <TableCell style={styles.tableCell}>{budget.organization}</TableCell>
                      <TableCell style={styles.tableCell} align="right">
                        {toAmountString(budget.value)}
                      </TableCell>
                      <TableCell style={styles.tableCell} align="right">
                        {budget.currencyCode}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={styles.analytics}>
                <Button
                  variant="outlined"
                  color="primary"
                  data-test="details-analytics-button"
                  onClick={openAnalyticsDialog}
                >
                  <BarChartIcon />
                  {strings.project.project_details}
                </Button>
              </div>
            </div>
          ) : (
            <BudgetEmptyState text={strings.common.no_budget_subproject} />
          )}
          </div>*/}
        <List style={styles.subprojectAssignee}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{statusIcon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={mappedStatus} secondary={strings.common.status} />
            {status !== "closed" ? (
              <Tooltip
                id="tooltip-sclose"
                title={subProjectCloseButtonTooltip(canCloseSubproject, closingOfSubProjectAllowed)}
              >
                <div>
                  <IconButton
                    aria-label="close subproject"
                    color="primary"
                    data-test="spc-button"
                    disabled={!closingOfSubProjectAllowed}
                    onClick={closeSubproject}
                    size="large"
                  >
                    <DoneIcon />
                  </IconButton>
                </div>
              </Tooltip>
            ) : null}
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AssigneeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <SubProjectAssigneeContainer
                  projectId={projectId}
                  subprojectId={subprojectId}
                  users={users}
                  disabled={!canAssignSubproject}
                  assignee={assignee}
                />
              }
              secondary={strings.subproject.assignee}
            />
          </ListItem>
          {/* FAS !_isEmpty(validator) ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <PersonIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={validator.displayName} secondary={strings.subproject.workflowitem_assignee} />
            </ListItem>
          ) : null FAS */}
        { /*POCBresil - BEGIN*/ }
          <ListItem>
            <ListItemAvatar>
              <Avatar>
              <IconButton onClick={() => {setQrVisible(true);}}>
                <QrCodeIcon/>
              </IconButton>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary = "Generate QR Code">
            </ListItemText>
          </ListItem>
          {/* POCBresil - END */}
        </List>
      </Card>
      <SubProjectAnalyticsDialog
        projectId={projectId}
        subProjectId={subprojectId}
        projectedBudgets={projectedBudgets}
      />
    </div>
  );
};
export default SubProjectDetails;
