import React, {useRef,useState} from "react";
//Bresil
import QRCode from "react-qr-code";
import _isEmpty from "lodash/isEmpty";

import DoneIcon from "@mui/icons-material/Check";
import DateIcon from "@mui/icons-material/DateRange";
import AssigneeIcon from "@mui/icons-material/Group";
import LabelIcon from "@mui/icons-material/Label";
import QrCodeIcon from '@mui/icons-material/QrCode';
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";

//Bresil
import { formattedTag, statusIconMapping, statusMapping, unixTsToString } from "../../helper.js";
import strings from "../../localizeStrings";
import ProjectAnalyticsDialog from "../Analytics/ProjectAnalyticsDialog";

import ProjectAssigneeContainer from "./ProjectAssigneeContainer";

const styles = {
  container: {
    display: "flex",
    height: "30%",
    flex: 1,
    flexDirection: "row",
    width: "100%",
    marginBottom: "24px",
    justifyContent: "space-between"
  },
  card: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  projectedBudget: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "18px",
    width: "32%"
  },
  projectDetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "18px",
    width: "31%",
    overflowWrap: "break-word"
  },
  projectAssignee: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "18px",
    width: "31%"
  },
  analytics: {
    padding: "12px 0 ",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  tableCell: {
    padding: "5px"
  }
};

const displayTags = (tags) => {
  return tags.map((tag, i) => (
    <Chip
      key={`${tag}-${i}`}
      label={`#${formattedTag(tag)}`}
      style={{ margin: "1px" }}
      clickable={false}
      size="small"
      component="span"
      data-test="project-details-tag"
    />
  ));
};


const ProjectDetails = (props) => {
  const {
    projectName,
    projectId,
    subProjects,
    projectComment,
    projectStatus,
    projectTS,
    projectAssignee,
    users,
    canAssignProject,
    closeProject,
    canClose,
    isDataLoading,
    projectProjectedBudgets,
    openAnalyticsDialog,
    projectTags
  } = props;
  
  const mappedStatus = statusMapping(projectStatus);
  const statusIcon = statusIconMapping[projectStatus];
  const hasOpenSubprojects = !_isEmpty(subProjects.find((subproject) => subproject.data.status === "open"));
  const closeDisabled = !canClose || hasOpenSubprojects || projectStatus === "closed";
  const tags = displayTags(projectTags || []);
  // POCBresil - BEGIN
  const [isQrVisible, setQrVisible] = useState(false);
  const qrRef = useRef(null);


  const downloadQR = () => {
    console.log("Attempting to download QR Code");
  
    if (!qrRef.current) {
      console.log("QR Code reference is null");
      return;
    }
  
    const svgElement = qrRef.current.querySelector("svg");
    if (!svgElement) {
      console.log("SVG element not found");
      return;
    }
  
    const svgData = new XMLSerializer().serializeToString(svgElement);
  
    // Création d'une image à partir des données SVG
    const img = new Image();
    img.src = 'data:image/svg+xml;base64,' + btoa(unescape(encodeURIComponent(svgData)));
  
    img.onload = () => {
      // Création d'un canvas et dessin de l'image dessus
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
  
      // Conversion du canvas en PNG et téléchargement
      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qr-code.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };
  };
  
  
  return (
    <div style={styles.container}>
      {isQrVisible ? (
        <div>
          <Dialog open={isQrVisible} onClose={() => { setQrVisible(false); }}>
            <DialogContent>
              <div ref={qrRef}>
                <QRCode
                  color="primary"
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={`projectId=${projectId}`}
                  viewBox={`0 0 256 256`}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={downloadQR}>
                Download
              </Button>
              <Button onClick={() => { setQrVisible(false); }} autoFocus>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : null}
    {/* // POCBresil - END */}
      <Card style={styles.card}>
        <List style={styles.projectDetails}>
          <ListItem>
            {projectName ? (
              <ListItemAvatar>
                <Avatar>{projectName[0]}</Avatar>
              </ListItemAvatar>
            ) : null}
            <ListItemText primary={projectName} secondary={projectComment} />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <DateIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={unixTsToString(projectTS)} secondary={strings.common.created} />
          </ListItem>
          {tags.length > 0 ? (
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <LabelIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={tags} />
            </ListItem>
          ) : null}
        </List>
        {/*<div style={styles.projectedBudget} data-test="project-projected-budget">
          <Typography variant="body1">{strings.common.total_budget}</Typography>
          
          {isDataLoading ? (
            <div />
          ) : projectProjectedBudgets.length > 0 ? (
            <div>
              <Table padding="none">
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.tableCell}>{strings.common.organization}</TableCell>
                    <TableCell style={styles.tableCell} align="right">
                      {strings.common.amount}
                    </TableCell>
                    <TableCell style={styles.tableCell} align="right">
                      {strings.common.currency}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectProjectedBudgets.map(budget => (
                    <TableRow key={budget.organization + budget.currencyCode}>
                      <TableCell style={styles.tableCell}>{budget.organization}</TableCell>
                      <TableCell style={styles.tableCell} align="right">
                        {toAmountString(budget.value)}
                      </TableCell>
                      <TableCell style={styles.tableCell} align="right">
                        {budget.currencyCode}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <div style={styles.analytics}>
                <Button
                  variant="outlined"
                  color="primary"
                  data-test="details-analytics-button"
                  onClick={openAnalyticsDialog}
                >
                  <BarChartIcon />
                  {strings.project.project_details}
                </Button>
              </div>
            </div>
          ) : (
            <BudgetEmptyState text={strings.common.no_budget_project} />
          )}
        </div>*/}
        <List style={styles.projectAssignee}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>{statusIcon}</Avatar>
            </ListItemAvatar>
            <ListItemText primary={mappedStatus} secondary={strings.common.status} />
            {projectStatus !== "closed" ? (
              <Tooltip
                id="tooltip-pclose"
                title={closeDisabled ? strings.project.project_close_info : strings.common.close}
              >
                <div>
                  <IconButton
                    aria-label="close"
                    color="primary"
                    data-test="pc-button"
                    disabled={closeDisabled}
                    onClick={closeProject}
                    size="large"
                  >
                    <DoneIcon />
                  </IconButton>
                </div>
              </Tooltip>
            ) : null}
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar style={styles.assingeeIcon}>
                <AssigneeIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <ProjectAssigneeContainer users={users} disabled={!canAssignProject} assignee={projectAssignee} />
              }
              secondary={strings.project.assignee}
            />
          </ListItem>
          {/* POCBresil - BEGIN */}
          <ListItem>
            <ListItemAvatar>
              <Avatar>
              <IconButton onClick={() => {setQrVisible(true);}}>
                <QrCodeIcon/>
              </IconButton>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary = "Generate QR Code">

            </ListItemText>
          </ListItem>
          {/* POCBresil - END */}
        </List>
      </Card>
      <ProjectAnalyticsDialog projectId={projectId} projectProjectedBudgets={projectProjectedBudgets} />
    </div>
  );
};

export default ProjectDetails;
